<template>
  <v-layout justify-start align-center>
    <v-flex shrink class="mr-2">
      <v-img
        height="25px"
        width="25px"
        contain
        :src="require('../../assets/icon/menu/' + name + '2.png')"
      />
    </v-flex>
    <v-flex shrink class="font-24 font-w-500 text-black">{{ topics }}</v-flex>
    <v-flex shrink class="pl-7">
      <v-btn @click="goto()" class="btn-blue" rounded>ดูเพิ่มเติม ></v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: ["topics", "name", "link"],
  methods: {
    goto() {
      this.$router.push(`/${this.link}`);
    },
  },
};
</script>

<style></style>
